import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "gatsby-image"

const PhotosPage = (props) => {
  const images = props.data.images.nodes.map(node => ({
      ...node.childImageSharp, id: node.id
  }));

  return (
    <Layout>
        <Seo title="Photos" />

        <h1 className="mb-16">Photos</h1>

        <div className="flex flex-col w-full gap-3 justify-center">
          {
            images.map(({ id, fluid }) => (
              <Img key={id} fluid={fluid} className="rounded-sm w-full" />
            ))
          }
        </div>
    </Layout>
  )
}

export const query = graphql`
  query ImagesForGallery {
    images: allFile(
      filter: { relativeDirectory: { eq: "gallery" } }
      sort: { fields: modifiedTime, order: DESC }
    ) {
      nodes {
        id
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

export default PhotosPage
